import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Main } from "page_components/order_confirmation"

const Account = () => {
  const title = "Potwierdzenie transakcji"

  return (
    <Layout
      seo={{
        title: title,
      }}
      isOrder
    >
      <Breadcrumbs title={title} />
      <PageHeader title="Gratulacje!" />
      <Main />
    </Layout>
  )
}

export default Account
